import React, { useEffect, useState } from 'react';

// // Style imports
import './buttontext.scss';

// // Utils imports
import visualizers from '../../utils/visualizers';

// // Component imports
import InteractionEffect from '../InteractionEffect/interactioneffect';

const ButtonText = (props) => {
  const { buttonCopy, buttonHit } = props;
  const [firstWordOfCopy, setFirstWordOfCopy] = useState('');
  const [isImpactEffectActive, setIsImpactEffectActive] = useState(false);
  const [xImpact, setXImpact] = useState(0);
  const [yImpact, setYImpact] = useState(0);

  // // ButtonText handler functions

  const copyFirstWordHandler = () => {
    const firstWordResults = buttonCopy.substr(0, buttonCopy.indexOf(' '));

    setFirstWordOfCopy(firstWordResults.toLowerCase());
  };

  const buttonClickHandler = () => {
    console.log('buttonClickHandler()');

    buttonHit();
  };

  // const buttonImpactHandler = (event) => {
  //   setXImpact(event.nativeEvent.offsetX - 10);
  //   setYImpact(event.nativeEvent.offsetY - 10);
  //   setIsImpactEffectActive(true);
  //   setTimeout(() => {
  //     setIsImpactEffectActive(false);
  //   }, 300);
  // };

  // // ButtonText Effect Hooks

  useEffect(() => {
    copyFirstWordHandler();
  // eslint-disable-next-line
  }, []);

  // // ButtonText React element

  return (
    <button className={
      'button-text ' +
      ` button-text--${firstWordOfCopy}`
    }
      onClick={buttonClickHandler}
      onMouseDown={(e) => visualizers.impactEffect(e, setXImpact, setYImpact, setIsImpactEffectActive)}
      // onMouseDown={(e) => buttonImpactHandler(e)}
    >
      <span className="button-text__copy">{buttonCopy}</span>

      {/*{isImpactEffectActive && <div className="button-text--impact grow"
        style={{left: xImpact + 'px', top: yImpact + 'px'}}
      ></div>}*/}

      {isImpactEffectActive &&
        <InteractionEffect
          // type="imp"
          // animation="grw"
          xEffect={xImpact}
          yEffect={yImpact}
        />
      }
    </button>
  );
};

export default ButtonText;
