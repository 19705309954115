// // Utils imports
import identifiers from '../../utils/identifiers';

const identifyApi = (instanceParam) => {
  let envApi = '';

  switch(instanceParam.designation) {
    case 'localhost':
      envApi = instanceParam.envApiDev;
      break;
    case 'dev':
      envApi = instanceParam.envApiDev;
      break;
    case 'stage':
      envApi = instanceParam.envApiStage;
      break;
    case 'prod':
      envApi = instanceParam.envApiProd;
      break;
    default:
      envApi = instanceParam.envApiDev;
      break;
  }

  return envApi;
};

const getContentItem = async (contentIdParam, instanceDataParam) => {
  const capturedApi = identifyApi(instanceDataParam);
  const resourcePath = `${capturedApi}/api/v1/app/webview/content-item/${contentIdParam}`;

  const contentResponse = await fetch(resourcePath, {
    method: 'GET',
    headers: {
      // 'Content-Type': 'application/json',
      'accept': 'application/json',
      'x-correlation-id': identifiers.generateCorrelationId(),
      'Accept-Language': 'en-US',
    },
  });

  if (contentResponse.ok) {
    const caughtData = await contentResponse.json();

    return caughtData.data;
  } else {
    const responseMessage = await contentResponse.text();

    throw new Error(`Status Code: ${contentResponse.status}, Response: ${responseMessage}`);
  }
};

const backendApis = {
  getContentItem,
};

export default backendApis;
