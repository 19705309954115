import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

// // API imports
import backendApis from '../apis/backend.apis';

// // Utils imports
import identifiers from '../../utils/identifiers';

const sGeneralAdapter = createEntityAdapter();

// // General reducer's initial state
const initialState = sGeneralAdapter.getInitialState({
  // // General flags (Booleans, Nulls, & Undefineds)
  hasProcessed: false,
  isAppInBeta: true,
  isContentCaptured: false,
  isDeviceHighlightsCompatible: false,
  isLoading: false,
  // // General figures (Numbers)
  attemptsAllowedToPullContent: 5,
  attemptsToPullContent: 0,
  // // General text (Strings)
  deviceType: '', // // 's' = Samsung, 'm' = Non-Samsung mobile, 'd' = Desktop
  lang: 'en',
  samsungDeviceModel: '',
  // // General lists (Arrays)
  ignoredSamsungDeviceItems: [
    // // Samsung Galaxy S10+
    'SM-G975F', 'SM-G975U', 'SM-G975W', 'SM-G975U1', 'SM-G9750', 'SM-G975N', 'SM-G975X',
    // // Samsung Galaxy S10+ Olympic Games Edition
    'SC-05L',
  ],
  // // General dictionaries (Objects)
  aboutWebApp: {
    name: 'Samsung Highlights',
    beta: 'Beta',
    tagline: 'Video Highlights, right on your lock screen.',
    // tagline: 'Samsung Highlights delivers videos just for you straight to your OneUI lock screen.',
  },
  instance: {
    designation: process.env.REACT_APP_ENV || identifiers.getHostnameBasedDesignationId(),
    hashFragment: window.location.hash,
    hostName: window.location.hostname,
    pathName: window.location.pathname,
    portNumber: window.location.port,
    protocolScheme: window.location.protocol,
    searchQuery: window.location.search,
    envApiDev: 'https://api-dev.shl-svc.com',
    envApiProd: 'https://api.shl-svc.com',
    envApiStage: 'https://api-stage.shl-svc.com',
  },
  linkData: {
    // pageGalaxyStoreMobile: 'https://galaxy.store/dd3',
    pageGalaxyStoreMobile: 'https://galaxy.store/ynexbcdmp',
    // pageGalaxyStoreWeb: 'https://galaxy.store/dd3',
    pageGalaxyStoreWeb: 'https://galaxy.store/ynexbcdmp',
    pageWebAppLanding: window.location.origin,
  },
  sharedData: {
    contentCaptionUrl: '',
    contentCategories: [{}],
    contentDescription: '',
    contentTitle: '',
    contentUuid: '',
    contentVidImgSrc: '',
    contentVidSrc: '',
    isContentApproved: false,
    publisherIconSrc: '',
    publisherName: '',
    publisherUrl: '',
  },
});

// // General Thunktions

export const fetchContentData = createAsyncThunk('general/fetchContentData', async (uuid, { getState, rejectWithValue }) => {
  try {
    const responseData = await backendApis.getContentItem(uuid, getState().general.instance);

    return responseData;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// // General reducer's actions
const sGeneralSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    resetViewerData(state) {
      // state.isContentCaptured = false;
      state.isDeviceHighlightsCompatible = false;
      state.attemptsToPullContent = 0;
      state.deviceType = '';
      state.samsungDeviceModel = '';
      state.sharedData.contentCaptionUrl = '';
      state.sharedData.contentCategories = [{}];
      state.sharedData.contentDescription = '';
      state.sharedData.contentTitle = '';
      state.sharedData.contentUuid = '';
      state.sharedData.contentVidImgSrc = '';
      state.sharedData.contentVidSrc = '';
      state.sharedData.isContentApproved = false;
      state.sharedData.publisherIconSrc = '';
      state.sharedData.publisherName = '';
      state.sharedData.publisherUrl = '';
    },
    setDeviceCompatibilityFlag(state, action) {
      state.isDeviceHighlightsCompatible = action.payload;
    },
    setLoadingFlag(state, action) {
      if (typeof action.payload == 'boolean') {
        state.isLoading = action.payload;
      } else {
        state.isLoading = false;
      }
    },
    setProcessedFlag(state, action) {
      if (typeof action.payload == 'boolean') {
        state.hasProcessed = action.payload;
      } else {
        state.hasProcessed = false;
      }
    },
    setSamsungDeviceModelText(state, action) {
      state.samsungDeviceModel = action.payload.toString();
    },
    setDeviceTypeText(state, action) {
      if (action.payload.toString() === 'iPhone') {
        state.deviceType = 'm';
      } else if (action.payload.toString() === 'Android') {
        if (state.samsungDeviceModel.length > 0) {
          state.deviceType = 's';
        } else {
          state.deviceType = 'm';
        }
      } else {
        state.deviceType = 'd';
      }
    },
    openCtaUrlLink(state, action) {
      let ctaLinkResults = state.linkData.pageWebAppLanding;

      if (state.deviceType === 's') {
        ctaLinkResults = state.linkData.pageGalaxyStoreMobile;
      } else if (state.deviceType === 'd') {
        ctaLinkResults = state.linkData.pageGalaxyStoreWeb;
      }

      window.open(ctaLinkResults, '_blank', 'noreferrer');
    },
    openGalaxyStoreLink(state, action) {
      let galaxystoreLinkResults = state.linkData.pageGalaxyStoreMobile;

      if (state.deviceType === 'd') {
        galaxystoreLinkResults = state.linkData.pageGalaxyStoreWeb;
      }

      window.open(galaxystoreLinkResults, '_blank', 'noreferrer');
    },
    openWebAppLandingLink(state, action) {
      window.open(state.linkData.pageWebAppLanding, '_blank', 'noreferrer');
    },
    launchPagesForwardLink(state, action) {
      const pagesForwardInfo = action.payload;
      let forwardLinkResults = state.linkData.pageGalaxyStoreWeb;

      if (pagesForwardInfo === 'gm') {
        forwardLinkResults = state.linkData.pageGalaxyStoreMobile;
      } else if (pagesForwardInfo === 'gw') {
        forwardLinkResults = state.linkData.pageGalaxyStoreWeb;
      } else if (pagesForwardInfo === 'lm') {
        forwardLinkResults = state.linkData.pageWebAppLanding;
      } else {
        forwardLinkResults = state.linkData.pageGalaxyStoreWeb;
      }

      // if (pagesForwardInfo === 'lm') {
      //   window.open(forwardLinkResults, '_blank', 'noreferrer');
      // } else {
      //   window.location.href = forwardLinkResults;
      // }

      window.open(forwardLinkResults, '_blank', 'noreferrer');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContentData.pending, (state, action) => {
        console.log('fetchContentData pending');
      })
      .addCase(fetchContentData.fulfilled, (state, action) => {
        if ((action.payload.categories === 'APPROVED') || (action.payload.categories === 'LOCKSCREEN_APPROVED')) {
          state.sharedData.isContentApproved = true;
        }

        state.sharedData.contentCaptionUrl = action.payload.call_to_action.cta_url;
        state.sharedData.contentCategories = action.payload.categories;
        state.sharedData.contentDescription = action.payload.call_to_action.cta_text;
        // state.sharedData.contentTitle = action.payload.title ? action.payload.title : '';
        state.sharedData.contentUuid = action.payload.uuid;
        state.sharedData.contentVidImgSrc = action.payload.fullscreen_image.url;
        state.sharedData.contentVidSrc = action.payload.playback_video.url;
        state.sharedData.publisherIconSrc = action.payload.publisher.publisher_icon.url;
        state.sharedData.publisherName = action.payload.publisher.publisher_name;

        if (action.payload.publisher.profile_link && action.payload.publisher.profile_link.link_url) {
          state.sharedData.publisherUrl = action.payload.publisher.profile_link.link_url;
        } else {
          state.sharedData.publisherUrl = action.payload.publisher.publisher_url;
        }

        state.hasProcessed = true;
        state.isContentCaptured = true;
        state.isLoading = false;

        console.log('fetchContentData fulfilled');
        // console.log(`fetchContentData fulfilled, action.payload: ${JSON.stringify(action.payload)}`);
      })
      .addCase(fetchContentData.rejected, (state, action) => {
        // state.envDataFetchError = true;
        state.attemptsToPullContent += 1;

        console.error(`fetchContentData rejected, payload: ${action.payload}`);
      });
  },
});

export const generalActions = sGeneralSlice.actions;

export default sGeneralSlice.reducer;
