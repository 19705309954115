const impactEffect = (inputEvent, inputXSetter, inputYSetter, inputActiveFlagSetter) => {
  // console.log('  visualizers impactEffect()');

  inputXSetter(inputEvent.nativeEvent.offsetX - 10);
  inputYSetter(inputEvent.nativeEvent.offsetY - 10);
  inputActiveFlagSetter(true);
  setTimeout(() => {
    inputActiveFlagSetter(false);
  }, 500);
};

const visualizers = {
  impactEffect,
};

export default visualizers;
