import galaxyStoreDownload from './images/badges/download-galaxy-store.png';
// import googlePlayStoreDownload from './images/badges/download-google-play-store.png';

import galaxyStoreQrCode from './images/barcodes/qr-code-galaxy-store.png';

import appLogo from './images/icons/logo-s-highlights.svg';
import crossSymbol from './images/icons/symbol-cross.svg';
import continueChevronCta from './images/icons/cta-continue-chevron.svg';
import playControl from './images/icons/control-play.svg';
import mutedAudio from './images/icons/audio-muted.svg';
import soniferousAudio from './images/icons/audio-soniferous.svg';
import webShare from './images/icons/share-web.svg';
import linkShare from './images/icons/share-link.svg';
import successStatus from './images/icons/status-success.svg';
import socialFacebook from './images/icons/social-facebook.svg';
import socialTwitter from './images/icons/social-twitter.svg';

import installButtonSmartBanner from './images/vendors/smb-images/dbtn.png';
import mainImageSmartBanner from './images/vendors/smb-images/mainimg.png';
import newCloseSmartBanner from './images/vendors/smb-images/newclose.png';
import sGStoreSmartBanner from './images/vendors/smb-images/sgstore.png';
import starSmartBanner from './images/vendors/smb-images/star.png';

import loadingRing from './animations/flux-json/flux-loading-ring-white.json';
import processingCircle from './animations/one-ui-json/one-ui-5x-progress-circle-indeterminate.json';

const museum = {
  galaxyStoreDownload,
  // googlePlayStoreDownload,

  galaxyStoreQrCode,

  appLogo,
  crossSymbol,
  continueChevronCta,
  playControl,
  mutedAudio,
  soniferousAudio,
  webShare,
  linkShare,
  successStatus,
  socialFacebook,
  socialTwitter,

  installButtonSmartBanner,
  mainImageSmartBanner,
  newCloseSmartBanner,
  sGStoreSmartBanner,
  starSmartBanner,

  loadingRing,
  processingCircle,
};

export default museum;
