import React, { useEffect, useRef, useState } from 'react';

// // Style imports
import './viewer.scss';

// // Store management imports
import { useDispatch, useSelector } from 'react-redux';
import { storeActions } from '../../global/store';

// // Utils imports
import visualizers from '../../utils/visualizers';

// // Module imports
import ViewerOverlayMobile from './viewerOverlayMobile';
import ViewerReadMore from './viewerReadMore';

// // Component imports
import Branding from '../../components/Branding/branding';
import InteractionEffect from '../../components/InteractionEffect/interactioneffect';
import ModalList from '../../components/ModalList/modallist';

// // Asset imports
import museum from '../../assets/museum';

const Viewer = (props) => {
  const dispatch = useDispatch();
  const generalStore = useSelector((store) => store.general);
  const pagesStore = useSelector((store) => store.pages);
  const [isMobileSized, setIsMobileSized] = useState(false);
  const [isVideoPaused, setIsVideoPaused] = useState(false);
  const [isVideoPlayIconAnimating, setIsVideoPlayIconAnimating] = useState(false);
  const [isImpactEffectActive, setIsImpactEffectActive] = useState(false);
  const [isAudioClickActive, setIsAudioClickActive] = useState(false);
  const [isShareClickActive, setIsShareClickActive] = useState(false);
  const [isDescriptionClickActive, setIsDescriptionClickActive] = useState(false);
  const [isReadMoreModalOpen, setIsReadMoreModalOpen] = useState(false);
  const [isShareContentModalOpen, setIsShareContentModalOpen] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(!pagesStore.isContentAudible);
  const [isDescriptionTruncated, setIsDescriptionTruncated] = useState(false);
  const [xImpact, setXImpact] = useState(0);
  const [yImpact, setYImpact] = useState(0);
  const vidRef = useRef(null);
  const modRef = useRef(null);
  const appTitle = generalStore.aboutWebApp.name;
  const appReleaseStage = generalStore.aboutWebApp.beta;
  const appReleaseBetaState = generalStore.isAppInBeta;
  const appSubtitle = generalStore.aboutWebApp.tagline;
  const publisherTitle = generalStore.sharedData.publisherName;
  const publisherSymbolImg = generalStore.sharedData.publisherIconSrc;
  const contentSynopsis = generalStore.sharedData.contentDescription;
  // const contentSubject = generalStore.sharedData.contentTitle;
  const contentSynopsisLink = generalStore.sharedData.contentCaptionUrl;
  const viewerActionPhrase = pagesStore.viewer.pageData.alluringCopy;
  const viewerLinkCopy = pagesStore.viewer.pageData.learnLinkCopy;
  const viewerButtonCopy = pagesStore.viewer.pageData.ctaButtonCopy;

  // const testSynopsis = '01 Canned pears can save you hours of prep time when a recipe calls for poaching like our “Easy-Fancy Pear Galette” __ -- __ 02 Canned pears can save you hours of prep time when a recipe calls for poaching like our “Easy-Fancy Pear Galette” __ -- __ 03 Canned pears can save you hours of prep time when a recipe calls for poaching like our “Easy-Fancy Pear Galette” __ -- __ 04 Canned pears can save you hours of prep time when a recipe calls for poaching like our “Easy-Fancy Pear Galette” __ -- __ 05 Canned pears can save you hours of prep time when a recipe calls for poaching like our “Easy-Fancy Pear Galette” __ -- __ 06 Canned pears can save you hours of prep time when a recipe calls for poaching like our “Easy-Fancy Pear Galette” __ -- __ 07 Canned pears can save you hours of prep time when a recipe calls for poaching like our “Easy-Fancy Pear __ -- __ 08 Canned pears can save you hours of prep time when a recipe calls for poaching like our “Easy-Fancy Pear Galette” __ -- __ 09 FIN!!!';

  // // Viewer handler functions

  const openCtaUrlHandler = () => {
    dispatch(storeActions.openCtaUrlLink());
  };

  const setContentPausedHandler = (operationParam) => {
    dispatch(storeActions.setContentPausedFlag(operationParam));
  };

  const toggleContentAudibilityHandler = () => {
    dispatch(storeActions.toggleContentAudibilityFlag());
  };

  const viewerClickHandler = (event) => {
    if (event.target.getAttribute('value') !== null) {
      if (event.target.getAttribute('value') === 'share') {
        console.log('Share Modal is in use.');
      } else if (event.target.getAttribute('value') === 'share-open') {
        console.log('Share Action Button.');
      } else {
        if (isShareContentModalOpen) {
          // console.log('CLOSING 01');

          disableShareModalFlagHandler();
        }
      }
    } else {
      if (isShareContentModalOpen) {
        // console.log('CLOSING 02');

        disableShareModalFlagHandler();
      }
    }
  };

  const videoStateClickHandler = () => {
    if (vidRef.current.paused) {
      // console.log('  PLAY TIME!');

      setContentPausedHandler(false);
      setIsVideoPaused(false);
      setIsVideoPlayIconAnimating(true);
      vidRef.current.play();
    } else {
      // console.log('  PAUSED!');

      setContentPausedHandler(true);
      setIsVideoPaused(true);
      setIsVideoPlayIconAnimating(true);
      vidRef.current.pause();
    }
  };

  const audioStateClickHandler = () => {
    setIsAudioClickActive(true);
    setIsVideoMuted(!isVideoMuted);
    toggleContentAudibilityHandler();
  };

  const toggleReadMoreModalFlagHandler = () => {
    setIsReadMoreModalOpen(!isReadMoreModalOpen);
  };

  const toggleShareModalFlagHandler = () => {
    setIsShareContentModalOpen(!isShareContentModalOpen);
  };

  const disableShareModalFlagHandler = () => {
    setIsShareContentModalOpen(false);
  };

  const readMoreStateClickHandler = () => {
    // console.log('readMoreStateClickHandler()');

    toggleReadMoreModalFlagHandler();
  };

  const shareStateClickHandler = () => {
    // console.log('shareStateClickHandler()');

    setIsShareClickActive(true);
    toggleShareModalFlagHandler();
  };

  const captionLinkPageHandler = () => {
    // console.log('captionLinkPageHandler()');

    setIsDescriptionClickActive(true);
    window.open(contentSynopsisLink, '_blank', 'noreferrer');
  };

  // const captionImpactHandler = (event) => {
  //   setXImpact(event.nativeEvent.offsetX - 10);
  //   setYImpact(event.nativeEvent.offsetY - 10);
  //   setIsImpactEffectActive(true);
  //   setTimeout(() => {
  //     setIsImpactEffectActive(false);
  //   }, 300);
  // };

  const callbackReadMoreStateHandler = () => {
    readMoreStateClickHandler();
  };

  const callbackCaptionLinkPageHandler = () => {
    captionLinkPageHandler();
  };

  const callbackCtaButtonHandler = () => {
    openCtaUrlHandler();
  };

  const callbackVideoStateHandler = () => {
    videoStateClickHandler();
  };

  const callbackAudioStateHandler = () => {
    audioStateClickHandler();
  };

  const callbackShareStateHandler = () => {
    shareStateClickHandler();
  };

  // // Viewer Effect Hooks

  useEffect(() => {
    const resizeinator = () => {
      if (window.innerWidth < 720) {
        setIsMobileSized(true);
      } else {
        setIsMobileSized(false);
      }
    };

    window.addEventListener('resize', resizeinator);

    return () => {
      window.removeEventListener('resize', resizeinator);
    };
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isImpactEffectActive) {
      setTimeout(() => {
        setIsAudioClickActive(false);
        setIsShareClickActive(false);
        setIsDescriptionClickActive(false);
      }, 500);
    }
  // eslint-disable-next-line
  }, [isImpactEffectActive]);

  useEffect(() => {
    if (isVideoPlayIconAnimating && isVideoPaused) {
      setTimeout(() => {
        setIsVideoPlayIconAnimating(false);
      }, 200);
    } else if (isVideoPlayIconAnimating && !isVideoPaused) {
      setTimeout(() => {
        setIsVideoPlayIconAnimating(false);
      }, 100);
    }
  // eslint-disable-next-line
  }, [isVideoPlayIconAnimating]);


  useEffect(() => {
    const descriptionElement = document.getElementsByClassName('flyer__description-copy');

    const truncationHandler = (statusParam) => {
      if (statusParam) {
        setIsDescriptionTruncated(true);
        descriptionElement[0].classList.add('truncated');
      } else {
        setIsDescriptionTruncated(false);
        descriptionElement[0].classList.remove('truncated');
      }
    };

    const truncateinator = () => {
      // // Mid Range
      if ((window.innerWidth > 720) && (window.innerWidth < 1280)) {
        // console.log('  Mid!');

        if (descriptionElement[0].scrollHeight > 200) {
          truncationHandler(true);
        } else {
          truncationHandler(false);
        }
      // // Large Range
      } else if ((window.innerWidth >= 1280) && (window.innerWidth < 1920)) {
        // console.log('  Large!');

        const viewW = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const mScale = (20 * (viewW / 1280)) * 10;
        const roundedMScale = Math.round(mScale * 1) / 1;

        // console.log('mScale =', mScale);
        // console.log('  descriptionElement[0].scrollHeight =', descriptionElement[0].scrollHeight);
        // console.log('    roundedMScale =', roundedMScale);

        // if (!isDescriptionTruncated && descriptionElement[0].scrollHeight > roundedMScale) {
        if (descriptionElement[0].scrollHeight > roundedMScale) {
          truncationHandler(true);
        } else {
          truncationHandler(false);
        }
      // // Max Range
      } else if (window.innerWidth >= 1920) {
        // console.log('  Max!');

        if (descriptionElement[0].scrollHeight > 300) {
          truncationHandler(true);
        } else {
          truncationHandler(false);
        }
      } else {
        truncationHandler(false);
      }
    };

    window.addEventListener('resize', truncateinator);

    if (contentSynopsis) {
      truncateinator();
    }

    return () => {
      window.removeEventListener('resize', truncateinator);
    };
  // eslint-disable-next-line
  }, [contentSynopsis]);

  // // Viewer React element

  return (
    <div className="viewer"
      onClick={(e) => viewerClickHandler(e)}
    >
      {generalStore.isLoading && <div className="nothing-to-view"></div>}

      {!generalStore.isLoading && <main className="viewer__container">
        {isReadMoreModalOpen && <ViewerReadMore
          videoDescriptionCopy={contentSynopsis}
          // videoDescriptionCopy={testSynopsis}
          closeHit={callbackReadMoreStateHandler}
        />}

        <aside className="viewer__about viewer__aside">
          <Branding
            titleCopy={appTitle}
            releaseCopy={appReleaseStage}
            releaseBetaStatus={appReleaseBetaState}
            subtitleCopy={appSubtitle}
            linkCopy={viewerLinkCopy}
            ctaButtonCopy={viewerButtonCopy}
            ctaButtonHit={callbackCtaButtonHandler}
          />
        </aside>

        <section className="viewer__center">
          <ViewerOverlayMobile
            titleCopy={appTitle}
            releaseCopy={appReleaseStage}
            publisherNameCopy={publisherTitle}
            publisherLogo={publisherSymbolImg}
            contentDescriptionCopy={contentSynopsis}
            // contentDescriptionCopy={testSynopsis}
            marketingCopy={viewerActionPhrase}
            ctaButtonCopy={viewerButtonCopy}
            linkCopy={viewerLinkCopy}
            isShareModalOpen={isShareContentModalOpen}
            readMoreHit={callbackReadMoreStateHandler}
            ctaButtonHit={callbackCtaButtonHandler}
            captionHit={callbackCaptionLinkPageHandler}
            videoHit={callbackVideoStateHandler}
            audioHit={callbackAudioStateHandler}
            shareHit={callbackShareStateHandler}
          />

          <video className="viewer__video"
            src={generalStore.sharedData.contentVidSrc}
            poster={generalStore.sharedData.contentVidImgSrc}
            muted={isVideoMuted}
            autoPlay={true}
            loop={true}
            playsInline
            controls={false}
            ref={vidRef}
            onClick={!isMobileSized ? videoStateClickHandler : undefined}
          ></video>

          <img className={
            'viewer__play-icon' +
            (isVideoPlayIconAnimating && isVideoPaused ? ' pop-fade-effect--in' : '') +
            (isVideoPlayIconAnimating && !isVideoPaused ? ' pop-fade-effect--out' : '') +
            (!isVideoPlayIconAnimating && !isVideoPaused ? ' is-gone' : '')
          } src={museum.playControl} alt="control play icon"
            onClick={!isMobileSized ? videoStateClickHandler : undefined}
          />
        </section>

        <aside className="viewer__clearance viewer__aside">
          <article className="flyer">
            <div className="flyer__container">
              <header className="flyer__header">
                <div className="flyer__publisher">
                  <img className="flyer__publisher-icon" src={publisherSymbolImg} alt="Publisher icon" />

                  <div className="flyer__publisher-name">
                    <span>{publisherTitle}</span>
                  </div>
                </div>

                <div className="flyer__action">
                  <div className="flyer__action-bubble"
                    onClick={audioStateClickHandler}
                    onMouseDown={(e) => visualizers.impactEffect(e, setXImpact, setYImpact, setIsImpactEffectActive)}
                  >
                    <img className={
                      'flyer__action-icon' +
                      ' flyer__action-icon--audio-muted' +
                      (isVideoMuted ? '' : ' is-gone')
                    } src={museum.mutedAudio} alt="audio muted icon" />
                    <img className={
                      'flyer__action-icon' +
                      ' flyer__action-icon--audio-soniferous' +
                      (!isVideoMuted ? '' : ' is-gone')
                    } src={museum.soniferousAudio} alt="audio soniferous icon" />

                    {isImpactEffectActive && isAudioClickActive &&
                      <InteractionEffect
                        // type="imp"
                        // animation="grw"
                        xEffect={xImpact}
                        yEffect={yImpact}
                      />
                    }
                  </div>

                  <div className="flyer__action-bubble"
                    onClick={shareStateClickHandler}
                    onMouseDown={(e) => visualizers.impactEffect(e, setXImpact, setYImpact, setIsImpactEffectActive)}
                    value="share-open"
                  >
                    <img className="flyer__action-icon flyer__action-icon--share-web" src={museum.webShare} alt="share web icon"
                      value="share-open"
                    />

                    {isImpactEffectActive && isShareClickActive &&
                      <InteractionEffect
                        // type="imp"
                        // animation="grw"
                        xEffect={xImpact}
                        yEffect={yImpact}
                      />
                    }
                  </div>

                  <div className={
                    'flyer__share' +
                    (!isShareContentModalOpen ? ' is-gone' : '')
                  }
                    ref={modRef}
                    value="share"
                  >
                    <ModalList />
                  </div>
                </div>
              </header>

              <div className="flyer__material">
                <div className="flyer__description">
                  <span className="flyer__description-copy">
                    <div className="flyer__description-link"
                      onClick={captionLinkPageHandler}
                      onMouseDown={(e) => visualizers.impactEffect(e, setXImpact, setYImpact, setIsImpactEffectActive)}
                      // onMouseDown={(e) => captionImpactHandler(e)}
                    ></div>

                    {isDescriptionTruncated && <div className="show-more"
                      onClick={readMoreStateClickHandler}
                    >Read More</div>}
                    {contentSynopsis}
                    {/*{testSynopsis}*/}
                  </span>
                </div>

                {/*{isImpactEffectActive && <div className="flyer__material--impact grow"
                  style={{left: xImpact + 'px', top: yImpact + 'px'}}
                ></div>}*/}

                {isImpactEffectActive && isDescriptionClickActive &&
                  <InteractionEffect
                    // type="imp"
                    // animation="grw"
                    xEffect={xImpact}
                    yEffect={yImpact}
                  />
                }
              </div>
            </div>
          </article>
        </aside>
      </main>}
    </div>
  );
};

export default Viewer;
