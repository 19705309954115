import React, { useEffect, useState } from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

// // Style imports
import './modallist.scss';

// // Store management imports
import { useSelector } from 'react-redux';

const ModalList = (props) => {
  const pagesStore = useSelector((store) => store.pages);
  const [isShareLinkCopied, setIsShareLinkCopied] = useState(false);
  const sharingList = pagesStore.viewer.pageData.shareContentItems;
  const sharingData = pagesStore.viewer.pageData.shareContentData;

  // // ModalList handler functions

  const copyLinkHandler = () => {
    navigator.clipboard.writeText(sharingData.hypeUrl);
    setIsShareLinkCopied(true);
  };

  // // ModalList Effect Hooks

  useEffect(() => {
    setIsShareLinkCopied(false);
  // eslint-disable-next-line
  }, []);

  // // ModalList React element

  return (
    <article className="modal-list"
      // onClick={scrimClickHandler}
      // onMouseDown={(e) => visualizers.impactEffect(e, setXImpact, setYImpact, setIsImpactEffectActive)}
      // // onMouseDown={(e) => buttonImpactHandler(e)}
      value="share"
    >
      {/*<div className="modal-list__scrim"
        onClick={scrimClickHandler}
      ></div>*/}

      <div className="modal-list__container">
        <ul className="modal-list__options"
          value="share"
        >
          <div className="react-share__ShareButton"
            onClick={() => copyLinkHandler()}
          >
            {!isShareLinkCopied && <li className="modal-list__item modal-list__item--link"
              value="share"
            >
              <img className="modal-list__icon modal-list__icon--link" src={sharingList[0].imgSrc} alt="share link"
                value="share"
              />

              <span className="modal-list__label modal-list__label--link"
                value="share"
              >{sharingList[0].labelText}</span>
            </li>}



            {isShareLinkCopied && <li className="modal-list__item modal-list__item--link-copied"
              value="share"
            >
              <img className="modal-list__icon modal-list__icon--link-copied" src={sharingList[0].imgAltSrc} alt="share link copied"
                value="share"
              />

              <span className="modal-list__label modal-list__label--link-copied"
                value="share"
              >{sharingList[0].labelAltText}</span>
            </li>}
          </div>

          <FacebookShareButton url={sharingData.hypeUrl}
            hashtag={sharingData.hypeHashTag}
            // quote={sharingData.hypeTitle}
          >
            <li className="modal-list__item modal-list__item--social modal-list__item--facebook"
              value="share"
            >
              <img className="modal-list__icon modal-list__icon--social modal-list__icon--facebook" src={sharingList[1].imgSrc} alt="share link"
                value="share"
              />

              <span className="modal-list__label modal-list__label--social modal-list__label--facebook"
                value="share"
              >{sharingList[1].labelText}</span>
            </li>
          </FacebookShareButton>

          <TwitterShareButton url={sharingData.hypeUrl}
            title={sharingData.hypeTitle}
            // via={sharingData.hypeProfile}
            hashtags={[sharingData.hypeHashTag]}
          >
            <li className="modal-list__item modal-list__item--social modal-list__item--twitter"
              value="share"
            >
              <img className="modal-list__icon modal-list__icon--social modal-list__icon--twitter" src={sharingList[2].imgSrc} alt="share link"
                value="share"
              />

              <span className="modal-list__label modal-list__label--social modal-list__label--twitter"
                value="share"
              >{sharingList[2].labelText}</span>
            </li>
          </TwitterShareButton>
        </ul>
      </div>
    </article>
  );
};

export default ModalList;
