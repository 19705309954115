// import React from 'react';
import React, { useEffect } from 'react';

// // Style imports
import './learnmore.scss';

// // Store management imports
import { useDispatch, useSelector } from 'react-redux';
import { storeActions } from '../../global/store';

// // Component imports
import SmartBanner from '../../components/SmartBanner/smartbanner';
import Branding from '../../components/Branding/branding';

const LearnMore = (props) => {
  const dispatch = useDispatch();
  const generalStore = useSelector((store) => store.general);
  const pagesStore = useSelector((store) => store.pages);
  const appTitle = generalStore.aboutWebApp.name;
  const appReleaseStage = generalStore.aboutWebApp.beta;
  const appReleaseBetaState = generalStore.isAppInBeta;
  const appSubtitle = generalStore.aboutWebApp.tagline;
  const learnMoreSynopsis01 = pagesStore.landing.pageData.aboutCopy01;
  const learnMoreSynopsis02 = pagesStore.landing.pageData.aboutCopy02;
  const learnMoreTerms = pagesStore.landing.pageData.termsLinkCopy;
  const learnMorePrivacy = pagesStore.landing.pageData.privacyLinkCopy;
  const learnMoreButtonCopy = pagesStore.viewer.pageData.ctaButtonCopy;

  // // LearnMore handler functions

  const launchPagesForwardHandler = () => {
    dispatch(storeActions.launchPagesForwardLink());
  };

  const callbackCtaButtonHandler = () => {
    launchPagesForwardHandler();
  };

  // // LearnMore Effect Hooks

  useEffect(() => {
    // // NOTE: Modified Smart Banner code for Prod
    if (generalStore.instance.hostName === 'hghlts.com') {
      console.log('  Dynamic SB code not in use on Prod');
    // // NOTE: Galaxy Store team's code
    } else {
      console.log('  Dynamic SB code in use');

      if (navigator.userAgentData) {
        console.log('++++ navigator.userAgentData present ++++');

        const smartBannerinator = () => {
          console.log('  SB Begin');

          fetch('https://res.galaxy.store/getbanner?pkg=com.samsung.android.interactivelockscreen&host_name=https://hghlts.com/')
            .then((response) => response.json())
            .then((data) => {
              document.getElementById('sm-content').innerHTML = data.html;
              const smscrp = document.createElement('script');
              smscrp.textContent = data.js;
              document.getElementById('sm-custom-js').appendChild(smscrp);

              // console.log(`  data: ${data}`);
              // console.log(`  data: ${JSON.stringify(data)}`);
              // console.log(`    data.html: ${data.html}`);
              // // console.log(`    data.html.body: ${data.html.body}`);
            })

          console.log('  SB End');
        };

        window.addEventListener('load', smartBannerinator);

        return () => {
          window.removeEventListener('load', smartBannerinator);
        };
      } else {
        console.log('  ---- navigator.userAgentData undefined ----');
      }
    }
  // eslint-disable-next-line
  }, []);

  // // LearnMore React element

  return (
    <div className="learn-more">
      {/*NOTE: Modified Smart Banner code for Prod*/}
      {generalStore.instance.hostName === 'hghlts.com' && <SmartBanner />}

      {/*NOTE: Galaxy Store team's code*/}
      {generalStore.instance.hostName !== 'hghlts.com' && <script id="sm-custom-js"></script>}
      {generalStore.instance.hostName !== 'hghlts.com' && <div className="smdiv" id="sm-content" ></div>}

      {/*<main className="learn-more__container">
      </main>*/}
      <Branding
        titleCopy={appTitle}
        releaseCopy={appReleaseStage}
        releaseBetaStatus={appReleaseBetaState}
        subtitleCopy={appSubtitle}
        marketingCopy01={learnMoreSynopsis01}
        marketingCopy02={learnMoreSynopsis02}
        hasTerms={true}
        termsCopy={learnMoreTerms}
        privacyCopy={learnMorePrivacy}
        ctaButtonCopy={learnMoreButtonCopy}
        ctaButtonHit={callbackCtaButtonHandler}
      />
    </div>
  );
};

export default LearnMore;
