import React from 'react';

// // NOTE: Galaxy Store team's code, do not alter the following
// //   - SmartBanner scripting
// //   - SmartBanner structure
// //   - SmartBanner styling

// // Style imports
import './smartbanner.scss';

// // Store management imports
import { useSelector } from 'react-redux';

// // Asset imports
import museum from '../../assets/museum';

const SmartBanner = (props) => {
  const generalStore = useSelector((store) => store.general);

  // // SmartBanner scripting

  // var mdl = document.getElementById('sm-content');
  var mdl = document.getElementById('myModal');
  // mdl.style.display = 'none';

  var apiurl = 'https://res.galaxy.store/runscript?pkg=com.samsung.android.interactivelockscreen&host_name=https://hghlts.com/';
  var querystr = 'usr_agnt=';

  // // NOTE: Modified Smart Banner code for Prod
  if (generalStore.instance.hostName === 'hghlts.com') {
    if ('userAgentData' in navigator){
      navigator
        .userAgentData.getHighEntropyValues(['model'])
        .then(values => {
          querystr += values.model;
          const requestUrl = apiurl + '&' + querystr;
          perform(requestUrl);
        })
    }
    else{
      perform(apiurl)
    }
  // // NOTE: Galaxy Store team's code
  } else {
    fetch(apiurl)
    .then(response => response.json())
    .then(data => {
      if(data['sam_mobile'] === 'yes'){
        checkCookies();
      } else {
        navigator
          .userAgentData.getHighEntropyValues(['model'])
          .then(values => {
            querystr += values.model;
            const requestUrl = apiurl + '?' + querystr;
            perform(requestUrl);
          })
      }
    })
    .catch(error => {
      console.log('Error:', error);
    });
  }

  function perform(rurl){
    fetch(rurl)
    .then(response => response.json())
    .then(data => {
        if(data['sam_mobile'] === 'yes'){
            checkCookies();
        }
    })
    .catch(error => {
      console.log('Error:', error);
    });
  }

  function checkCookies() {
    // mdl.style.display = 'none';

    if (getCookie('sm_banner_24hr') === null) {
        mdl.style.display = 'flex';
    } else {
        mdl.style.display = 'none';
    }
  }
  function getCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  function setCookie(name, days) {
    var expires = '';
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60000);
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=1' + expires;
  }
  function galaxyLink() {
    window.location.href = 'https://galaxy.store/body4';
  }
  function galaxyDownloadLink() {
    hideBoth();
    window.location.href = 'https://galaxy.store/dd3';
  }
  function hideBoth() {
    setCookie('sm_banner_24hr', 1);
    var crsapiurl = 'https://res.galaxy.store/crossclick?pkg=com.samsung.android.interactivelockscreen';
    fetch(crsapiurl);
    mdl.style.display = 'none';
  }

  return (
    // // SmartBanner structure

    <div id="myModal" className="sm_banner_maindiv" style={{ display: 'none' }}>
      <div className="sm_banner_appbanner1">
        <div className="sm_banner_bannerclose">
          <img className="sm_banner_closebtn" src={museum.newCloseSmartBanner} alt="Close banner"
            // onclick="hideBoth()"
            onClick={hideBoth}
          />
        </div>
        <div className="sm_banner_appLogo">
          <img className="sm_banner_applogoImage" src={museum.mainImageSmartBanner} alt="Highlights App logo"
            // onclick="window.location.href='https://galaxy.store/body4';"
            onClick={galaxyLink}
          />
        </div>
        <div className="sm_banner_appinfo"
          // onclick="window.location.href='https://galaxy.store/body4';"
          onClick={galaxyLink}
        >
          <div className="sm_banner_title">Samsung Highlights</div>
          <div className="sm_banner_statement">Samsung Electronics Co. Ltd.</div>
          <div className="sm_banner_app_rating">
            <div>
              <img className="sm_banner_star" src={museum.starSmartBanner} alt="Rating star" />
            </div>
            <div className="sm_banner_count">5.0 . Rated 12+</div>
          </div>
        </div>
        <div className="sm_banner_rightsec">
          <div className="sm_banner_insbtn">
            <img className="sm_banner_ins" src={museum.installButtonSmartBanner} alt="Install"
              // onclick="hideBoth();window.location.href='https://galaxy.store/dd3';"
              onClick={galaxyDownloadLink}
            />
          </div>
        </div>
      </div>
      <div className="sm_banner_lower">
        <hr className="sm_banner_borderLine" />
        <div className="sm_banner_tc">
          <div className="sm_banner_terms">
            By clicking install, you agree to the <a href="https://terms.samsungconsent.com/6mztkyy858/TC/1.0/USA/USA_eng.html">Terms and
            Conditions</a> and <a href="https://terms.samsungconsent.com/6mztkyy858/PN/1.3/USA/USA_eng.html">Privacy Notice.</a>
          </div>
          <div className="sm_banner_icon1">
            <img className="sm_banner_galaxy_icon" src={museum.sGStoreSmartBanner} alt="Galaxy Store logo"
              // onclick="window.location.href='https://galaxy.store/body4';"
              onClick={galaxyLink}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartBanner;
