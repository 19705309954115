import React, { useEffect, useState } from 'react';

// // Routing management imports
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';

// // Style imports
import './App.scss';

// // Store management imports
import { Provider, useDispatch, useSelector } from 'react-redux';
import store, { storeActions } from './global/store';

// // Page imports
import LearnMore from './pages/LearnMore/learnmore';
import Viewer from './pages/Viewer/viewer';

// // Component imports
import LottieAnimator from './components/LottieAnimator/lottieanimator';

// // Asset imports
import museum from './assets/museum';

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const generalStore = useSelector((store) => store.general);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isSamsungDevice, setIsSamsungDevice] = useState(false);
  const [deviceKindInfo, setDeviceKindInfo] = useState('');
  const [deviceBrandInfo, setDeviceBrandInfo] = useState('');

  const lottieLabelText = 'Loading';

  // // App helper functions

  const deviceTypeDetectionHelper = (deviceTypeDataParam) => {
    const mobileMatch = deviceTypeDataParam.match(/Android|BlackBerry|IEMobile|iPad|iPhone|iPod|webOS|Windows Phone/i);

    if (mobileMatch) {
      setDeviceTypeHandler(mobileMatch);
      setIsMobileDevice(true);
      // alert('MOBILE!');
    } else {
      setDeviceTypeHandler('');
      setIsMobileDevice(false);
    }
  };

  const deviceBrandDetectionHelper = (deviceBrandDataParam) => {
    const samsungMatch = deviceBrandDataParam.match(/SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G|F]|SHV-E|SCH-[I|J|R|S]|SPH-L|SC-[0|5]/i);

    if (samsungMatch) {
      setSamsungDeviceModelHandler(samsungMatch);
      setIsSamsungDevice(true);
      // alert('SAMSUNG!');

      setDeviceCompatibilityStatusHandler(true);
    } else {
      setSamsungDeviceModelHandler('');
      setIsSamsungDevice(false);

      setDeviceCompatibilityStatusHandler(false);
    }
  };

  // // App handler functions

  const fetchContentDataHandler = (uuidParam) => {
    dispatch(storeActions.fetchContentData(uuidParam));
  };

  const setDeviceCompatibilityStatusHandler = (compatibilityParam) => {
    dispatch(storeActions.setDeviceCompatibilityFlag(compatibilityParam));
  };

  const setLoadingStatusHandler = (loadingParam) => {
    dispatch(storeActions.setLoadingFlag(loadingParam));
  };

  const setProcessedStatusHandler = (processingParam) => {
    dispatch(storeActions.setProcessedFlag(processingParam));
  };

  const setSamsungDeviceModelHandler = (deviceModelParam) => {
    dispatch(storeActions.setSamsungDeviceModelText(deviceModelParam));
  };

  const setDeviceTypeHandler = (deviceTypeParam) => {
    dispatch(storeActions.setDeviceTypeText(deviceTypeParam));
  };

  const setDevicDynamicDetailsHandler = (deviceParam) => {
    dispatch(storeActions.setDevicDynamicDetailsData(deviceParam));
  };

  const setSharedContentHandler = (shareParam = { shareTitle: '', shareUrl: '' }) => {
    dispatch(storeActions.setSharedContentData(shareParam));
  };

  const nowGo = () => {
    if (navigator.userAgentData) {
      return navigator.userAgentData
        .getHighEntropyValues([
          // 'architecture',
          // 'bitness',
          // 'brands',
          // 'mobile',
          'model',
          'platform',
          // 'platformVersion',
          // 'fullVersionList',
        ])
        .then((userAgentValue) => {
          setDeviceKindInfo(userAgentValue.platform);
          setDeviceBrandInfo(userAgentValue.model);
          return userAgentValue;
        });
    } else {
      setDeviceKindInfo(navigator.userAgent);
      setDeviceBrandInfo(navigator.userAgent);
    }
  };

  // // App Effect Hooks

  useEffect(() => {
    let urlUuid;

    nowGo();

    if (
      (location.pathname.startsWith('//share/') && (location.pathname.length > 8))
      || (location.pathname.startsWith('/share//') && (location.pathname.length > 8))
    ) {
      if (location.pathname.startsWith('/share//')) {
        urlUuid = location.pathname.split('/share//')[1];
      } else {
        urlUuid = location.pathname.split('//share/')[1];
      }

      navigate(`/share/${urlUuid}`, { replace: true });
      navigate(0);
    } else if (location.pathname.startsWith('/share/') && (location.pathname.length > 7)) {
      urlUuid = location.pathname.split('/share/')[1];
      if (!generalStore.hasProcessed) {
        if (generalStore.attemptsToPullContent < generalStore.attemptsAllowedToPullContent) {
          fetchContentDataHandler(urlUuid);
          setLoadingStatusHandler(true);
        } else {
          setProcessedStatusHandler(true);
        }
      } else if (generalStore.hasProcessed && !(generalStore.isContentCaptured)) {
        navigate('../', { replace: true });
        setLoadingStatusHandler(false);
      }
    } else {
      navigate('../', { replace: true });
      setLoadingStatusHandler(false);
    }
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith('/share/') && (location.pathname.length > 7)) {
      if (generalStore.attemptsToPullContent < generalStore.attemptsAllowedToPullContent) {
        const urlUuid = location.pathname.split('/share/')[1];
        fetchContentDataHandler(urlUuid);
        setLoadingStatusHandler(true);
      } else {
        navigate('../', { replace: true });
        setLoadingStatusHandler(false);
      }
    }
    // eslint-disable-next-line
  }, [generalStore.attemptsToPullContent]);

  useEffect(() => {
    deviceBrandDetectionHelper(deviceBrandInfo);
    deviceTypeDetectionHelper(deviceKindInfo);
  // eslint-disable-next-line
  }, [deviceBrandInfo]);

  useEffect(() => {
    setDevicDynamicDetailsHandler(generalStore.deviceType);
  // eslint-disable-next-line
  }, [generalStore.deviceType]);

  useEffect(() => {
    if (!generalStore.isLoading) {
      console.log('isMobileDevice:', isMobileDevice);
      console.log('isSamsungDevice:', isSamsungDevice);
    }
  // eslint-disable-next-line
  }, [generalStore.isLoading]);

  useEffect(() => {
    if (generalStore.hasProcessed) {
      // console.log('location:', location);

      const shareContentinator = {
        shareTitle: `Check out ${generalStore.sharedData.publisherName} on Samsung Highlights`,
        shareUrl: window.location.href,
      };

      setSharedContentHandler(shareContentinator);
    }
  // eslint-disable-next-line
  }, [generalStore.hasProcessed]);

  // // App React element

  return (
    <main className="App">
      {/*<p><code>isMobileDevice: </code>{isMobileDevice.toString()}</p>*/}
      {/*<p><code>generalStore.deviceType: </code>{generalStore.deviceType}</p>
      <p><code>generalStore.samsungDeviceModel: </code>{generalStore.samsungDeviceModel}</p>
      <p><code>generalStore.samsungDeviceModel.length: </code>{generalStore.samsungDeviceModel.length}</p>*/}
      {generalStore.isLoading && <div className="loading">
        <div className="loading__container">
          <LottieAnimator
            options={{
              loop: true,
              animationData: museum.processingCircle,
            }}
            lottieLabel={lottieLabelText}
          />
        </div>
      </div>}

      <Provider store={store}>
        <Routes>
          <Route path="/" element={<LearnMore />} />

          <Route path="/share/">
            <Route path=":contentUuid" element={<Viewer />} />

            <Route path="" element={<LearnMore />} />
          </Route>

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Provider>
    </main>
  );
};

export default App;
