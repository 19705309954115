import React from 'react';
// import React, { useEffect, useState } from 'react';

// // Utils imports
// import visualizers from '../../utils/visualizers';

// // Style imports
import './interactioneffect.scss';

// // TODO: Fill out parameter definitions

// // InteractionEffect Types
// //   - Impact: WWW
// //   - Invert: XXX

// // InteractionEffect Animations
// //   - Grow: YYY
// //   - Vanish: ZZZ

const InteractionEffect = (props) => {
  // // @Params
  // // type: 'imp' = impact (default), 'inv' = invert
  // // animation: 'grw' = grow (default), 'van' = vanish
  const { type, animation, xEffect, yEffect } = props;
  // const [isImpactEffectActive, setIsImpactEffectActive] = useState(false);
  // const [xImpact, setXImpact] = useState(0);
  // const [yImpact, setYImpact] = useState(0);

  // // InteractionEffect handler functions

  // const buttonClickHandler = () => {
  //   console.log('buttonClickHandler()');
  //
  //   buttonHit();
  // };


  const applyTypeHandler = () => {
    let interactionTypeResults = ' interaction-effect__impact';
    // let interactionTypeResults = 'interaction-effect__impact';

    if (type === 'inv') {
      interactionTypeResults = ' interaction-effect__invert';
      // interactionTypeResults = 'interaction-effect__invert';
    }

    return interactionTypeResults;
  };

  const applyAnimationHandler = () => {
    let interactionAnimationResults = ' interaction-effect--grow';

    if (animation === 'van') {
      interactionAnimationResults = ' interaction-effect--vanish';
    }

    return interactionAnimationResults;
  };

  // const buttonImpactHandler = (event) => {
  //   setXImpact(event.nativeEvent.offsetX - 10);
  //   setYImpact(event.nativeEvent.offsetY - 10);
  //   setIsImpactEffectActive(true);
  //   setTimeout(() => {
  //     setIsImpactEffectActive(false);
  //   }, 300);
  // };

  // // InteractionEffect Effect Hooks

  // useEffect(() => {
  //   console.log('Empty UseEffect()');
  // // eslint-disable-next-line
  // }, []);

  // // InteractionEffect React element

  return (
    // <div className="interaction-effect"
    <div className={
      'interaction-effect' +
        applyTypeHandler() +
        applyAnimationHandler()
    }
      style={{ left: xEffect + 'px', top: yEffect + 'px' }}
    >
{/*      <div className={
        applyTypeHandler() +
        applyAnimationHandler()
      }></div>*/}
    </div>
  );
};

export default InteractionEffect;
