import React, { useEffect, useState } from 'react';

// // Style imports
import './viewer.scss';

// // Utils imports
import visualizers from '../../utils/visualizers';

// // Component imports
import InteractionEffect from '../../components/InteractionEffect/interactioneffect';

// // Asset imports
import museum from '../../assets/museum';

const ViewerReadMore = (props) => {
  const { videoDescriptionCopy, closeHit } = props;
  const [isImpactEffectActive, setIsImpactEffectActive] = useState(false);
  const [isCloseClickActive, setIsCloseClickActive] = useState(false);
  const [xImpact, setXImpact] = useState(0);
  const [yImpact, setYImpact] = useState(0);

  // // ViewerReadMore handler functions

  const closeClickHandler = () => {
    // console.log('closeClickHandler()');

    setIsCloseClickActive(true);
    closeHit();
  };

  // // ViewerReadMore Effect Hooks

  useEffect(() => {
    if (isImpactEffectActive) {
      setTimeout(() => {
        setIsCloseClickActive(false);
        // setIsButtonClickActive(false);
      }, 500);
    }
  // eslint-disable-next-line
  }, [isImpactEffectActive]);

  // // ViewerReadMore React element

  return (
    <section className="viewer-read-more">
      <div className="viewer-read-more__container">
        <header className="viewer-read-more__header">
          <div className="viewer-read-more__action">
            <div className="viewer-read-more__action-bubble"
              onClick={closeClickHandler}
              onMouseDown={(e) => visualizers.impactEffect(e, setXImpact, setYImpact, setIsImpactEffectActive)}
            >
              <img className="viewer-read-more__action-icon viewer-read-more__action-icon--symbol-cross" src={museum.crossSymbol} alt="symbol cross icon" />

              {isImpactEffectActive && isCloseClickActive &&
                <InteractionEffect
                  // type="imp"
                  // animation="grw"
                  xEffect={xImpact}
                  yEffect={yImpact}
                />
              }
            </div>
          </div>
        </header>

        <div className="viewer-read-more__text-container">
          <div className="viewer-read-more__description">
              <span className="viewer-read-more__description-copy">
                {videoDescriptionCopy}
              </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewerReadMore;
