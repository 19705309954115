import { configureStore } from '@reduxjs/toolkit';

import generalReducer, { fetchContentData, generalActions } from './state/general.state';
import pagesReducer, { pagesActions } from './state/pages.state';

const store = configureStore({
  reducer: {
    // // Top-level state
    general: generalReducer,
    // // App page flow monitoring state
    // // Rest of state
    pages: pagesReducer,
  },
});

export const storeActions = {
  ...generalActions,
  fetchContentData,
  ...pagesActions,
};

export default store;
