import { v4 } from 'uuid';

const generateCorrelationId = () => {
  // console.log('  identifiers generateCorrelationId()');

  return v4();
};

const getHostnameBasedDesignationId = () => {
  const hostnameInfo = window.location.hostname;
  let designationResults;

  // console.log('  identifiers getHostnameBasedDesignationId()');

  if (hostnameInfo === 'localhost') {
    designationResults = 'localhost';
  } else if (hostnameInfo === 'web-dev.hghlts.com') {
    designationResults = 'dev';
  } else if (hostnameInfo === 'webview-dev.hghlts.com') {
    designationResults = 'dev';
  } else if (hostnameInfo === 'web-stage.hghlts.com') {
    designationResults = 'stage';
  } else if (hostnameInfo === 'hghlts.com') {
    designationResults = 'prod';
  } else {
    designationResults = 'dev';
  }

  return designationResults;
};

const identifiers = {
  generateCorrelationId,
  getHostnameBasedDesignationId,
};

export default identifiers;
