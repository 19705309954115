import React from 'react';

// // Style imports
import './branding.scss';

// // Asset imports
import museum from '../../assets/museum';

const BrandingShowroom = (props) => {
  const { badgeHit } = props;
  const copyAvailability = 'Only available on Samsung Galaxy devices';

  // // BrandingShowroom handler functions

  const badgeClickHandler = () => {
    console.log('badgeClickHandler()');

    badgeHit();
  };

  // // BrandingShowroom React element

  return (
    <div className="branding-showroom">
      <div className="branding-showroom__wrapper">
        <div className="branding-showroom__caption">
          <span className="branding-showroom__copy">{copyAvailability}</span>
        </div>

        <img className="branding-showroom__qr-code" src={museum.galaxyStoreQrCode} alt="QR code" />

        <img className="branding-showroom__get-it" src={museum.galaxyStoreDownload} alt="Available on Galaxy Store"
          onClick={badgeClickHandler}
        />
      </div>
    </div>
  );
};

export default BrandingShowroom;
