import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

// // Asset imports
import museum from '../../assets/museum';

const sPagesAdapter = createEntityAdapter();

// // Pages reducer's initial state
const initialState = sPagesAdapter.getInitialState({
  // // Pages flags (Booleans, Nulls, & Undefineds)
  isContentAudible: false,
  isContentPaused: false,
  // // Pages figures (Numbers)
  ctaText: 'Try it',
  // ctaText: 'Get the app',
  forwardGalaxyStoreMobile: 'gm', // // Galaxy Store mobile app page
  forwardGalaxyStoreWeb: 'gw',    // // Galaxy Store web page
  forwardWebAppLanding: 'lm',     // // Learn More web app page
  kickerDesktopText: 'Video Highlights, on your lock screen.',
  // kickerDesktopText: 'Video Highlights, on your lock screen.',
  // // kickerDesktopText: 'Videos just for you on your lock screen.',
  kickerMobileText: 'Video Highlights, on your lock screen.',
  // kickerMobileText: 'Videos for you, on your lock screen',
  kickerSamsungText: 'Video Highlights, on your lock screen.',
  // kickerSamsungText: 'Try the full lock screen experience',
  landText: 'Learn more',
  // // Pages text (Strings)
  //
  // // Pages lists (Arrays)
  //
  // // Pages dictionaries (Objects)
  landing: {
    pageLevel: 0,
    pageName: 'Learn More',
    pageKey: 'learnMore',
    pageLabel: 'learn-more',
    pageData: {
      learnLinkCopy: '',
      termsLinkCopy: 'Terms and Conditions',
      privacyLinkCopy: 'Privacy Notice',
      ctaButtonCopy: '',
      ctaButtonRoute: '',
      aboutCopy01: 'Samsung Highlights is the fastest way to be entertained and inspired - right on your lock screen. Swipe up to watch video highlights, curated for you, and tap to discover more.',
      aboutCopy02: 'Enjoy video highlights across entertainment, sports, news, food, tech and more. Your Samsung Highlights video feed is relevant and personalized to your interests. Just watch, like, and share your favorite videos, and Highlights will recommend more.',
    },
  },
  viewer: {
    pageLevel: 1,
    pageName: 'Viewer',
    pageKey: 'viewer',
    pageLabel: 'viewer',
    pageData: {
      alluringCopy: '',
      learnLinkCopy: '',
      ctaButtonCopy: '',
      ctaButtonRoute: '',
      shareContentItems: [
        {
          isSocialLink: false,
          labelText: 'Copy Link',
          labelAltText: 'Link Copied',
          imgSrc: museum.linkShare,
          imgAltSrc: museum.successStatus,
        }, {
          isSocialLink: true,
          labelText: 'Facebook',
          labelAltText: 'Facebook',
          imgSrc: museum.socialFacebook,
          imgAltSrc: museum.socialFacebook,
        }, {
          isSocialLink: true,
          labelText: 'X',
          labelAltText: 'Twitter',
          imgSrc: museum.socialTwitter,
          imgAltSrc: museum.socialTwitter,
        },
      ],
      shareContentData: {
        hypeTitle: 'Samsung Highlights delivers videos just for you',
        hypeUrl: 'https://hghlts.com/',
        hypeHashTag: 'SamsungHighlights',
        hypeProfile: 'Samsung',
        listValue: 'share',
      },
    },
  },
});

// // Pages reducer's actions
const sPagesSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setDevicDynamicDetailsData(state, action) {
      const deviceInfo = action.payload;

      state.landing.pageData.learnLinkCopy = state.landText;
      state.landing.pageData.ctaButtonCopy = state.ctaText;

      state.viewer.pageData.learnLinkCopy = state.landText;
      state.viewer.pageData.ctaButtonCopy = state.ctaText;

        // state.landing.pageData.ctaButtonRoute = state.viewer.pageData.deviceSamsung.ctaButtonRoute;
      if (deviceInfo === 's') {
        state.landing.pageData.ctaButtonRoute = state.forwardGalaxyStoreMobile;

        state.viewer.pageData.alluringCopy = state.kickerSamsungText;
        state.viewer.pageData.ctaButtonRoute = state.forwardGalaxyStoreMobile;
      } else if (deviceInfo === 'm') {
        state.landing.pageData.ctaButtonRoute = state.forwardGalaxyStoreWeb;
        state.landing.pageData.ctaButtonCopy = state.landText;

        state.viewer.pageData.alluringCopy = state.kickerMobileText;
        state.viewer.pageData.ctaButtonCopy = state.landText;
        state.viewer.pageData.ctaButtonRoute = state.forwardWebAppLanding;
      } else {
        // state.landing.pageData.ctaButtonRoute = state.forwardWebAppLanding;
        state.landing.pageData.ctaButtonRoute = state.forwardGalaxyStoreWeb;
        state.landing.pageData.ctaButtonCopy = state.landText;

        state.viewer.pageData.alluringCopy = state.kickerDesktopText;
        state.viewer.pageData.ctaButtonCopy = state.landText;
        state.viewer.pageData.ctaButtonRoute = state.forwardGalaxyStoreWeb;
      }
    },
    setSharedContentData(state, action) {
      const shareObject = action.payload;

      if (shareObject && shareObject.shareTitle) {
        state.viewer.pageData.shareContentData.hypeTitle = shareObject.shareTitle;
      }
      if (shareObject && shareObject.shareUrl) {
        state.viewer.pageData.shareContentData.hypeUrl = action.payload.shareUrl;
      }
    },
    setContentPausedFlag(state, action) {
      state.isContentPaused = action.payload;
    },
    toggleContentAudibilityFlag(state) {
      state.isContentAudible = !state.isContentAudible;
    },
  },
  extraReducers: (builder) => {
  },
});

export const pagesActions = sPagesSlice.actions;

export default sPagesSlice.reducer;
