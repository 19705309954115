import React from 'react';
import ReactDOM from 'react-dom/client';

// // Routing management imports
// import { HashRouter as Router } from 'react-router-dom';
//
import { BrowserRouter } from 'react-router-dom';
//
// import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
// import historyTracking from './global/historyTracking';

// // Store management imports
import { Provider } from 'react-redux';
import store from './global/store';

// // Main component(s) imports
import App from './App';

// // Style imports
import './index.scss';

// // Testing & performance imports
import reportWebVitals from './reportWebVitals';

// // Create a React "root" for the supplied "container"
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
// const root = ReactDOM.createRoot(document.getElementById('root'));

// // Render the single "root" DOM node
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
