import React from 'react';
import lottie from 'lottie-web';

// // Style imports
// import './lottieanimator.scss';

class LottieAnimator extends React.Component {
  componentDidMount() {
    const { options, eventListeners } = this.props;
    const {
      renderer = 'svg',
      rendererSettings,
      loop,
      autoplay,
      animationData,
      path,
      assetsPath,
    } = options;

    this.options = {
      // // the DOM element that will contain the animation
      container: this.elem,
      // // The method by which to render the animation
      renderer,
      // // A configuration object if you want to use an existing canvas to draw
      rendererSettings,
      // // Number of times it will loop
      loop: loop !== false,
      // // If it will start playing as soon as it is ready
      autoplay: autoplay !== false,
      // // The animation data *REQUIRED*
      animationData,
      // // The path to the animation JSON *REQUIRED*
      path,
      // // The root path for external assets *REQUIRED*
      assetsPath,
    };
    this.options = { ...this.options, ...options };
    this.anim = lottie.loadAnimation(this.options);
    this.setterOfSpeed();
    this.setterOfDirection();
    this.setterOfAnimationControl();
    this.enrollEventsHelper(eventListeners);

    if (this.props.isStopped) {
      this.setterOfStateStop();
    } else if (this.props.segments) {
      this.setterOfPlaySegmentsUsage(true);
    } else {
      this.setterOfStatePlay();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.options.animationData !== prevProps.options.animationData) {
      this.dismissEventsHelper(this.props.eventListeners);
      this.destructionHelper();
      this.options = { ...this.options, ...prevProps.options };
      this.anim = lottie.loadAnimation(this.options);
      this.enrollEventsHelper(prevProps.eventListeners);
    }

    if (this.props.options.loop !== undefined) {
      this.anim.loop = this.props.options.loop;
    }

    if (this.props.isStopped) {
      this.setterOfStateStop();
    } else if (this.props.segments) {
      if (JSON.stringify(this.props.segments) === JSON.stringify(prevProps.segments)) {
        return;
      }
      this.setterOfPlaySegmentsUsage(this.props.isPlayingSegmentsImmediately);
    } else {
      this.setterOfStatePlay();
    }

    this.setterOfAnimationControl();
    this.setterOfStatePause();
    this.setterOfSpeed();
    this.setterOfDirection();
  }

  componentWillUnmount() {
    this.dismissEventsHelper(this.props.eventListeners);
    this.destructionHelper();
    this.options.animationData = null;
    this.anim = null;
  }

  setterOfSpeed() {
    this.anim.setSpeed(this.props.speed);
  }

  setterOfDirection() {
    this.anim.setDirection(this.props.direction);
  }

  setterOfAnimationControl() {
    const { animationControl } = this.props;
    if (animationControl) {
      const properties = Object.keys(animationControl);

      properties.forEach((property) => {
        // eslint-disable-next-line
        const value = animationControl[property];
      });
    }
  }

  setterOfStatePlay() {
    this.anim.play();
  }

  setterOfStateStop() {
    this.anim.stop();
  }

  setterOfStatePause() {
    if (this.props.isPaused && !this.anim.isPaused) {
      this.anim.pause();
    } else if (!this.props.isPaused && this.anim.isPaused) {
      this.anim.pause();
    }
  }

  setterOfPlaySegmentsUsage(isMakingSegmentsPlayImmediately) {
    this.anim.playSegments(this.props.segments, isMakingSegmentsPlayImmediately);
  }

  enrollEventsHelper(eventListeners) {
    eventListeners.forEach((eventListener) => {
      this.anim.addEventListener(eventListener.eventName, eventListener.callback);
    });
  }

  dismissEventsHelper(eventListeners) {
    eventListeners.forEach((eventListener) => {
      this.anim.removeEventListener(eventListener.eventName, eventListener.callback);
    });
  }

  destructionHelper() {
    this.anim.destroy();
  }

  render() {
    const {
      lottieLabel,
    } = this.props;

    return (
      <div className="lottie-animator"
        ref={(inputComp) => {
          this.elem = inputComp;
        }}
        lottielabel={lottieLabel}
      ></div>
    );
  }
}

LottieAnimator.defaultProps = {
  lottieLabel: '',
  eventListeners: [],
  speed: 1,
  isPaused: false,
  isStopped: false,
};

export default LottieAnimator;
