import React from 'react';

// // Style imports
import './branding.scss';

// // Store management imports
import { useDispatch } from 'react-redux';
import { storeActions } from '../../global/store';

// // Sub-Component imports
import BrandingShowroom from './brandingShowroom';

// // Component imports
import ButtonText from '../ButtonText/buttontext';

// // Asset imports
import museum from '../../assets/museum';

const Branding = (props) => {
  const { titleCopy, releaseCopy, releaseBetaStatus, subtitleCopy, marketingCopy01, marketingCopy02, linkCopy, ctaButtonCopy, hasTerms, termsCopy, privacyCopy, ctaButtonHit } = props;
  const dispatch = useDispatch();

  // // Branding handler functions

  const openGalaxyStoreHandler = () => {
    dispatch(storeActions.openGalaxyStoreLink());
  };

  const callbackButtonHandler = () => {
    console.log('  ');
    console.log('  YO!!!  callbackButtonHandler()');
    console.log('  ');

    ctaButtonHit();
  };

  const callbackStoreBadgeHandler = () => {
    openGalaxyStoreHandler();
  };

  // // Branding React element

  return (
    <section className="branding">
      <div className="branding__container">
        <header className="branding__header">
          <img className="branding__logo" src={museum.appLogo} alt="Samsung Highlights Logo" />

          <div className="branding__title">
            <span className="branding__title-copy">{titleCopy}</span>
          </div>

          <div className={
            'branding__badge-label' +
            (releaseBetaStatus ? '' : ' is-hidden')
          }>
            <span>{releaseCopy}</span>
          </div>
        </header>

        <article className="branding__details">
          <div className="branding__subtitle">
            <span>{subtitleCopy}</span>
          </div>

          {marketingCopy01 && <div className="branding__copy">
            <span>{marketingCopy01}</span>
          </div>}

          {marketingCopy02 && <div className="branding__copy">
            <span>{marketingCopy02}</span>
          </div>}

          {linkCopy && <a className="branding__link"
            href="/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkCopy}<img className="branding__continue-chevron" src={museum.continueChevronCta} alt="Call to Action continuation chevron" />
          </a>}

          {/*<a className="branding__link"
            href="/"
            target="_blank"
            rel="noopener noreferrer"
          >{copyLink}</a>*/}
        </article>

        <article className="branding__download">
          <div className="branding__button-holder">
            <ButtonText
              buttonCopy={ctaButtonCopy}
              buttonHit={callbackButtonHandler}
            />
          </div>

          <BrandingShowroom
            badgeHit={callbackStoreBadgeHandler}
          />
        </article>

        {hasTerms && <article className="branding__footer">
          <div className="branding__legal">
            {termsCopy && <a className="branding__doc"
              href="/terms.html"
              target="_blank"
              rel="noopener noreferrer"
            >{termsCopy}</a>}

            {privacyCopy && <a className="branding__doc"
              href="/privacy.html"
              target="_blank"
              rel="noopener noreferrer"
            >{privacyCopy}</a>}
          </div>
        </article>}
      </div>
    </section>
  );
};

export default Branding;
