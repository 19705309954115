import React, { useEffect, useState } from 'react';

// // Style imports
import './viewer.scss';

// // Utils imports
import visualizers from '../../utils/visualizers';

// // Component imports
import ButtonText from '../../components/ButtonText/buttontext';
import InteractionEffect from '../../components/InteractionEffect/interactioneffect';
import ModalList from '../../components/ModalList/modallist';

// // Asset imports
import museum from '../../assets/museum';

const ViewerOverlayMobile = (props) => {
  const { titleCopy, releaseCopy, publisherNameCopy, publisherLogo, contentDescriptionCopy, marketingCopy, ctaButtonCopy, isShareModalOpen, readMoreHit, ctaButtonHit, captionHit, videoHit, audioHit, shareHit } = props;
  const [isSoundOn, setIsSoundOn] = useState(false);
  const [isImpactEffectActive, setIsImpactEffectActive] = useState(false);
  const [isReadMoreClickActive, setIsReadMoreClickActive] = useState(false);
  const [isAudioClickActive, setIsAudioClickActive] = useState(false);
  const [isShareClickActive, setIsShareClickActive] = useState(false);
  const [isDescriptionClickActive, setIsDescriptionClickActive] = useState(false);
  const [isDescriptionMobileTruncated, setIsDescriptionMobileTruncated] = useState(false);
  const [xImpact, setXImpact] = useState(0);
  const [yImpact, setYImpact] = useState(0);

  // // ViewerOverlayMobile handler functions

  const readMoreStateClickHandler = () => {
    console.log('readMoreStateClickHandler()');

    setIsReadMoreClickActive(true);
    readMoreHit();
  };

  const videoClickHandler = () => {
    // console.log('videoClickHandler()');

    videoHit();
  };

  const descriptionClickHandler = () => {
    // console.log('descriptionClickHandler()');

    setIsDescriptionClickActive(true);
    captionHit();
  };

  // const descriptionImpactHandler = (event) => {
  //   setXImpact(event.nativeEvent.offsetX - 10);
  //   setYImpact(event.nativeEvent.offsetY - 10);
  //   setIsImpactEffectActive(true);
  //   setTimeout(() => {
  //     setIsImpactEffectActive(false);
  //   }, 300);
  // };

  const audioClickHandler = () => {
    // console.log('audioClickHandler()');

    setIsAudioClickActive(true);
    setIsSoundOn(!isSoundOn);
    audioHit();
  };

  const shareClickHandler = () => {
    // console.log('shareClickHandler()');

    setIsShareClickActive(true);
    shareHit();
  };

  const callbackAppPageHandler = () => {
    // console.log('callbackAppPageHandler()');

    setIsDescriptionClickActive(true);
    ctaButtonHit();
  };

  // // ViewerOverlayMobile Effect Hooks

  useEffect(() => {
    if (isImpactEffectActive) {
      setTimeout(() => {
        setIsReadMoreClickActive(false);
        setIsAudioClickActive(false);
        setIsShareClickActive(false);
        setIsDescriptionClickActive(false);
        // setIsButtonClickActive(false);
      }, 500);
    }
  // eslint-disable-next-line
  }, [isImpactEffectActive]);

  useEffect(() => {
    const descriptionMobileElement = document.getElementsByClassName('viewer-overlay-mobile__description-copy');

    const truncationMobileHandler = (statusParam) => {
      if (statusParam) {
        setIsDescriptionMobileTruncated(true);
        descriptionMobileElement[0].classList.add('truncated');
      } else {
        setIsDescriptionMobileTruncated(false);
        descriptionMobileElement[0].classList.remove('truncated');
      }
    };

    const mobileTruncateinator = () => {
      // // Mobile Range
      // console.log('  Large!');
      if (descriptionMobileElement[0].scrollHeight > 100) {
        truncationMobileHandler(true);
      } else {
        truncationMobileHandler(false);
      }
    };

    window.addEventListener('resize', mobileTruncateinator);

    if (contentDescriptionCopy) {
      mobileTruncateinator();
    }

    return () => {
      window.removeEventListener('resize', mobileTruncateinator);
    };
  // eslint-disable-next-line
  }, [contentDescriptionCopy]);

  // // ViewerOverlayMobile React element

  return (
    <section className="viewer-overlay-mobile">
      <header className="viewer-overlay-mobile__header"
        onClick={videoClickHandler}
      >
        <div className="viewer-overlay-mobile__top">
          <img className="viewer-overlay-mobile__logo" src={museum.appLogo} alt="logo" />

          <div className="viewer-overlay-mobile__headline">
            <span className="viewer-overlay-mobile__headline-copy">{titleCopy}</span>
          </div>

          <span className="viewer-overlay-mobile__badge-label">{releaseCopy}</span>
        </div>
      </header>

      <div className="viewer-overlay-mobile__body-click-field"
        onClick={videoClickHandler}
      ></div>

      <footer className="viewer-overlay-mobile__footer">
        <div className="viewer-overlay-mobile__footer-click-field"
          onClick={videoClickHandler}
        ></div>

        <div className="viewer-overlay-mobile__shelf">
          <div className="viewer-overlay-mobile__publisher">
            <img className="viewer-overlay-mobile__publisher-icon" src={publisherLogo} alt="Publisher icon" />

            <div className="viewer-overlay-mobile__publisher-name">
              <span>{publisherNameCopy}</span>
            </div>
          </div>

          <div className="viewer-overlay-mobile__action">
            <div className="viewer-overlay-mobile__action-bubble"
              onClick={audioClickHandler}
              onMouseDown={(e) => visualizers.impactEffect(e, setXImpact, setYImpact, setIsImpactEffectActive)}
            >
              <img className={
                'viewer-overlay-mobile__action-icon' +
                ' viewer-overlay-mobile__action-icon--audio-muted' +
                (!isSoundOn ? '' : ' is-gone')
              } src={museum.mutedAudio} alt="audio muted icon" />
              <img className={
                'viewer-overlay-mobile__action-icon' +
                ' viewer-overlay-mobile__action-icon--audio-soniferous' +
                (isSoundOn ? '' : ' is-gone')
              } src={museum.soniferousAudio} alt="audio soniferous icon" />

              {isImpactEffectActive && isAudioClickActive &&
                <InteractionEffect
                  // type="imp"
                  // animation="grw"
                  xEffect={xImpact}
                  yEffect={yImpact}
                />
              }
            </div>

            <div className="viewer-overlay-mobile__action-bubble"
              onClick={shareClickHandler}
              onMouseDown={(e) => visualizers.impactEffect(e, setXImpact, setYImpact, setIsImpactEffectActive)}
            >
              <img className="viewer-overlay-mobile__action-icon viewer-overlay-mobile__action-icon--share-web" src={museum.webShare} alt="share web icon" />

              {isImpactEffectActive && isShareClickActive &&
                <InteractionEffect
                  // type="imp"
                  // animation="grw"
                  xEffect={xImpact}
                  yEffect={yImpact}
                />
              }
            </div>

            {isShareModalOpen && <div className="viewer-overlay-mobile__share"
              // ref={modRef}
              value="share"
            >
              <ModalList />
            </div>}
          </div>
        </div>

        <div className="viewer-overlay-mobile__material">
          <div className="viewer-overlay-mobile__description">
            <span className="viewer-overlay-mobile__description-copy">
              <div className="viewer-overlay-mobile__description-link"
                onClick={descriptionClickHandler}
                onMouseDown={(e) => visualizers.impactEffect(e, setXImpact, setYImpact, setIsImpactEffectActive)}
                // onMouseDown={(e) => descriptionImpactHandler(e)}
              ></div>

              {isDescriptionMobileTruncated && <div className="show-more"
                onClick={readMoreStateClickHandler}
              >Read More</div>}
              {contentDescriptionCopy}
            </span>
          </div>

          {/*{isImpactEffectActive && <div className="viewer-overlay-mobile__material--impact grow"
            style={{left: xImpact + 'px', top: yImpact + 'px'}}
          ></div>}*/}

          {isImpactEffectActive && isDescriptionClickActive &&
            <InteractionEffect
              // type="imp"
              // animation="grw"
              xEffect={xImpact}
              yEffect={yImpact}
            />
          }
        </div>

        <div className="viewer-overlay-mobile__now-go">
          <div className="viewer-overlay-mobile__marketing">
            <div className="viewer-overlay-mobile__marketing-caption">
              <span className="viewer-overlay-mobile__marketing-copy">
                {marketingCopy}
                {/*{marketingCopy}{marketingCopy}{marketingCopy}{marketingCopy}*/}

                {/*{isDesktop && linkCopy && <a className="viewer-overlay-mobile__link"
                  href="/"
                  target="_blank"
                  rel="noopener noreferrer"
                >{linkCopy}></a>}*/}
                </span>
            </div>

            <ButtonText
              buttonCopy={ctaButtonCopy}
              buttonHit={callbackAppPageHandler}
            />
          </div>
        </div>
      </footer>
    </section>
  );
};

export default ViewerOverlayMobile;
